export class ContactPersonModel{
    name: string;
    position: string;
    phoneNumber: string;
    email: string;

    constructor(){
        this.name="";
        this.position="";
        this.phoneNumber="";
        this.email="";
    }
}