import React from 'react'
import GetFeatured from './GetFeatured'

const Footer = () => {
    return (
        <div>
            {/* Begin:: Get started  Newsletter*/}
            <GetFeatured />
            {/* End :: Get started Newsletter*/}

            {/* <!--Site Footer Start--> */}
            <footer className="site-footer">
                {/* <!-- <div className="site-footer__shape-1 float-bob-y">
                <img src="images/shapes/footer-shape-1.png" alt=""></img>
            </div> --> */}
                <span className="gl-testimonial-map position-absolute"><img src="images/backgrounds/map3.png"
                    alt=""></img></span>
                <div className="site-footer__top">
                    <div className="container">
                        <div className="site-footer__top-inner">
                            <div className="row">
                                <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="100ms">
                                    <div className="footer-widget__column footer-widget__about">
                                        {/* <!-- <div className="footer-widget__logo">
                                        <a href="index.html"><img src="images/resources/logo-1wn.png" alt=""></img></a>
                                    </div> --> */}
                                        {/* <p className="footer-widget__about-text">Welcome to 1WN - One World One Network , a global network curated to help elevate businesses & forge bonds in a secured environment, of its exclusive members from the logistics and freight forwarding industry</p> */}
                                        <div className="footer-widget__title-box">
                                            <h3 className="footer-widget__title">Our Offices</h3>
                                        </div>
                                        <ul className="footer-widget__about-contact list-unstyled">

                                            <li>
                                                <div className="icon">
                                                    <span className="icon-location location-icon"></span>
                                                </div>
                                                <p>Radisson Blu Plaza Hotel,<br></br>
                                                    Delhi Airport, New Delhi -110037</p>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-location location-icon"></span>
                                                </div>
                                                <p>IFZA Business Park,<br></br>
                                                    Dubai Silicon Oasis, Dubai</p>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-location location-icon"></span>
                                                </div>
                                                <p>511, 8W Building,<br></br> DAFZA Dubai Airport Free<br></br> Zone,
                                                    Dubai Airport, UAE .</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="200ms">
                                    <div className="footer-widget__column footer-widget__company">
                                        <div className="footer-widget__title-box">
                                            <h3 className="footer-widget__title">Quick Links</h3>
                                        </div>
                                        <ul className="footer-widget__company-list list-unstyled">
                                            <li>
                                                <a href="/faqs">FAQs</a>
                                            </li>
                                            <li>
                                                {/* <!-- <a href="mission.html">Our Mission</a> --> */}
                                            </li>
                                            <li>
                                                <a href="/membership-benifits">Membership Benefits</a>
                                            </li>
                                            <li>
                                                <a href="/cookie-policies">Cookie Policies</a>
                                            </li>
                                            <li>
                                                <a href="/privacy-policies">Privacy Policies</a>
                                            </li>
                                            <li>
                                                <a href="/terms-and-conditions">Terms and Conditions</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="300ms">
                                    <div className="footer-widget__column footer-widget__services">
                                        <div className="footer-widget__title-box">
                                            <h3 className="footer-widget__title">Highlights</h3>
                                        </div>
                                        <ul className="footer-widget__services-list list-unstyled">
                                            <li>
                                                <a href="/event-and-conference"><span className="icon-angle-left"></span>Event & Conference</a>
                                            </li>
                                            <li>
                                                <a href="/news-and-media"><span className="icon-angle-left"></span>News &
                                                    Media</a>
                                            </li>
                                            <li>
                                                <a href="/conferences"><span className="icon-angle-left"></span>1WN Conferences</a>
                                            </li>
                                            <li>
                                                <a href="/post-event-gallery"><span className="icon-angle-left"></span>Post Event Gallery</a>
                                            </li>
                                            <li>
                                                <a href="/csr"><span className="icon-angle-left"></span>CSR</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="400ms">
                                    <div className="footer-widget__column footer-widget__newsletter">
                                        <div className="footer-widget__title-box">
                                            <h3 className="footer-widget__title">Newsletter</h3>
                                        </div>
                                        <p className="footer-widget__newsletter-text">Freight Forwarding Fraternity to join us</p>
                                        <form className="footer-widget__newsletter-form mc-form" data-url="MC_FORM_URL"
                                            novalidate="novalidate">
                                            <div className="footer-widget__newsletter-form-input-box">
                                                <input type="email" placeholder="Your Email" name="EMAIL" />
                                                <button type="submit" className="footer-widget__newsletter-btn"><span
                                                    className="icon-paper-plane"></span></button>
                                            </div>
                                        </form>
                                        <div className="mc-form__response"></div>
                                    </div>
                                    <ul className="footer-widget__about-contact list-unstyled mt-4">
                                        <li>
                                            <div className="icon">
                                                <span className="icon-envolop email-icon"></span>
                                            </div>
                                            <p><a href="mailto:debra.holt@example.com">info@oneworldnetwork.co</a></p>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <span className="icon-call phone-call"></span>
                                            </div>
                                            <p><a href="tel:+971 54 522 3903">+971 54 522 3903</a></p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="site-footer__bottom">
                    <div className="container">
                        <div className="site-footer__bottom-inner text-center">
                            <p className="site-footer__bottom-text">©2024 All Rights Reserved, Designed & Developed by <a
                                href="https://csgtech.in/" target="_blank"> CSG.</a></p>
                        </div>
                    </div>
                </div>
            </footer>
            {/* Site FooterEnd */}
        </div>
    )
}

export default Footer
