import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, useFormikContext } from 'formik';
import RECAPTCHA from "react-google-recaptcha";
import FormikControl from 'components/wrapperComponents/FormikControl'; // Adjust the path based on your project structure
import {
    Box,
    Grid,
    Paper,
    Step,
    StepLabel,
    Stepper,
    Button,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';

import { MembershipFormModel } from 'model/screensModels/membershipRegistration/MembershipFormModel';
import MembershipFormSchema from "schemas/membershipForm/MembershipFormSchema";
import { MembershipService } from 'services/menu/membership/MembershipService';
import { msgTypes } from 'constants/msgTypes';
import { UtilService } from 'services/shared/UtilService';
import MembershipContactPerson from './MembershipContactPerson';



const MembershipForm = () => {
    const [captchaMatched, setCaptchaMatched] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [initialValues, setInitialValues] = useState(new MembershipFormModel());
    const [memberlist, setMemberList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const recaptcha = useRef();

    const validation = MembershipFormSchema

    useEffect(() => {
        loadCountry();
    }, [])

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);

    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    //load country
    const loadCountry = async () => {
        const res = await UtilService.getCountryList();
        if (res.statusMsg === msgTypes.SUCCESS.msg) {
            setCountryList(res.data);
        }
    }

    //load state list
    const loadStateList = async (countryId) => {
        const res = await UtilService.getStateList(countryId);
        if (res.statusMsg === msgTypes.SUCCESS.msg) {
            setStateList(res.data);
        }
    }

    //load City list
    const loadCityList = async (stateId) => {
        const res = await UtilService.getCityList(stateId);
        if (res.statusMsg === msgTypes.SUCCESS.msg) {
            setCityList(res.data);
        }
    }


    const onCaptchaSubmit = (value) => {
        if (value)
            setCaptchaMatched(true)
        else
            setCaptchaMatched(false)
    }


    const submitMembership = async (values) => {
        const res = await MembershipService.addMembership(values)
        if (res.statusMsg === msgTypes.SUCCESS.msg) {
            setActiveStep(0);
            setMemberList([]);
            return true
        }
        return false;
    }

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box width="100%" height="100%" margin="0 auto" padding="0px">
            <Typography variant="h3" align="center" marginBottom="50px">
                Membership Form
            </Typography>
            <Stepper
                activeStep={activeStep}
                orientation={isSmallScreen ? 'vertical' : 'horizontal'}
                sx={{
                    width: '80%',
                    backgroundColor: 'transparent',
                    marginBottom: '13px',
                    fontWeight: '300',
                    margin: '5 auto',
                    fontSize: '1.5rem' // Adjust the font size for larger labels
                }}
            >
                {[
                    'Company Details',
                    'Company Address',
                    'Company Description',
                    'Company Social Profiles',
                    'Membership Type',
                    'Contact Person',
                ].map((label, index) => (
                    <Step key={index}>
                        <StepLabel
                            sx={{
                                '& .MuiStepLabel-label': {
                                    fontSize: '1.0rem', // Adjust the font size for larger labels
                                    fontWeight: 'bold',
                                    textTransform: 'uppercase'
                                }
                            }}
                        >
                            {label}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Box className="main-box" width="80%" margin="0 auto" padding="20px" marginBottom='60px'>
                <Grid container justifyContent="left">
                    <Paper
                        className="event-paper"
                        style={{
                            padding: '20px',
                            marginBottom: '50px',
                            marginTop: '50px',
                            backgroundColor: '#ffffff'
                        }}
                    >
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validation[activeStep]}
                            onSubmit={async (values, { resetForm }) => {
                                if (activeStep < validation.length - 1) {
                                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                                }
                                const res = await submitMembership(values);
                                if (res)
                                    resetForm();
                            }}
                        >
                            {({ handleSubmit, isSubmitting, isValid, dirty, values, formik, setFieldValue, setFieldTouched }) => (
                                <Form onSubmit={handleSubmit}>

                                    {activeStep === 0 && (
                                        <>
                                            <Typography variant="h4" align="center" marginBottom="30px">
                                                Company Details
                                            </Typography>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6}>
                                                    <FormikControl
                                                        control="textfield"
                                                        name="companyName"
                                                        label="Company Name"
                                                        placeholder="Enter Your Company Name"
                                                        requiredField
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <FormikControl
                                                        control="textfield"
                                                        name="companyEmail"
                                                        label="Company Email"
                                                        placeholder="Enter Your Company Email"
                                                        requiredField
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <FormikControl
                                                        control="textfield"
                                                        name="primaryPhoneNumber"
                                                        label="Mobile Number"
                                                        placeholder="Enter Your Mobile Number"
                                                        requiredField
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <FormikControl
                                                        control="textfield"
                                                        name="secondaryPhoneNumber"
                                                        label="Alternative Mobile Number"
                                                        placeholder="Enter Your Alternative Mobile Number"

                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <FormikControl
                                                        control="textfield"
                                                        name="websiteUrl"
                                                        label="URL"
                                                        placeholder="Enter Your URL"

                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <FormikControl
                                                        control="textfield"
                                                        name="accountingEmail"
                                                        label="Accounting Email"
                                                        placeholder="Enter Your Accounting Email"

                                                    />
                                                </Grid>
                                            </Grid>
                                            <Box marginTop="20px" className="next-btn" display="flex" justifyContent="space-between">
                                                <Button
                                                    //type="submit"
                                                    className='btn btn-submit'
                                                    onClick={handleNext}
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={!dirty || !(isValid)}
                                                >
                                                    Next
                                                </Button>
                                            </Box>
                                        </>
                                    )}
                                    {activeStep === 1 && (
                                        <>
                                            <Typography variant="h4" align="center" marginBottom="30px">
                                                Company Address
                                            </Typography>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6}>
                                                    <FormikControl
                                                        control="textfield"
                                                        name="addressLine1"
                                                        label="Address"
                                                        placeholder="Enter Your Address"
                                                        requiredField
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <FormikControl control='autocomplete'
                                                        label="Country"
                                                        name="country"
                                                        placeholder="Select Country"
                                                        requiredField={true}
                                                        options={countryList}
                                                        onChange={(e) => { loadStateList({ "country": e.countryIdentifier }) }}
                                                        keyfield="name"
                                                        valuefield="countryIdentifier"
                                                    />


                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <FormikControl control='autocomplete'
                                                        label="State"
                                                        name="state"
                                                        placeholder="Select State"
                                                        requiredField={true}
                                                        options={stateList}
                                                        onChange={(e) => { loadCityList({ "state": e.stateIdentifier }) }}
                                                        keyfield="name"
                                                        valuefield="stateIdentifier"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <FormikControl control='autocomplete'
                                                        label="City"
                                                        name="city"
                                                        placeholder="Select City"
                                                        requiredField={true}
                                                        options={cityList}
                                                        onChange={(e) => { loadCityList({ "state": e.stateIdentifier }) }}
                                                        keyfield="name"
                                                        valuefield="stateIdentifier"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <FormikControl
                                                        control="textfield"
                                                        name="zipCode"
                                                        label="Zip Code"
                                                        placeholder="Enter Your Company Zip Code"
                                                        requiredField
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Box marginTop="20px" display="flex" justifyContent="space-between">
                                                <Button
                                                    className='btn btn-back'
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleBack}
                                                >
                                                    Back
                                                </Button>
                                                <Button
                                                    //type="submit"
                                                    className='btn btn-submit'
                                                    onClick={handleNext}
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={!dirty || !isValid || values.addressLine1.length === 0}
                                                >
                                                    Next
                                                </Button>
                                            </Box>
                                        </>
                                    )}
                                    {activeStep === 2 && (
                                        <>
                                            <Typography variant="h4" align="center" marginBottom="30px">
                                                Company Description
                                            </Typography>
                                            <Grid className="text-area" item xs={12}>
                                                <FormikControl
                                                    control="textfield"
                                                    label="Company Details"
                                                    type="text"
                                                    variant="outlined"
                                                    requiredField
                                                    multiline
                                                    rows={4}
                                                    fullWidth
                                                    sx={{ m: 1 }}
                                                    name="companyDetails"
                                                    placeholder="Company Details"
                                                />
                                            </Grid>
                                            <Box marginTop="20px" display="flex" justifyContent="space-between">
                                                <Button
                                                    className='btn btn-back'
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleBack}
                                                >
                                                    Back
                                                </Button>
                                                <Button
                                                    className='btn btn-submit'
                                                    onClick={handleNext}
                                                    //type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={!dirty || !(isValid) || values.companyDetails.length === 0}
                                                >
                                                    Next
                                                </Button>
                                            </Box>
                                        </>
                                    )}
                                    {activeStep === 3 && (
                                        <>
                                            <Typography variant="h4" align="center" marginBottom="30px">
                                                Company Social Profiles
                                            </Typography>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6}>
                                                    <FormikControl
                                                        control="textfield"
                                                        name="instagramUrl"
                                                        label="Instagram URL"
                                                        placeholder="Enter Your Instagram URL"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <FormikControl
                                                        control="textfield"
                                                        name="linkedinUrl"
                                                        label="LinkedIn URL"
                                                        placeholder="Enter Your LinkedIn URL"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <FormikControl
                                                        control="textfield"
                                                        name="twitterUrl"
                                                        label="Twitter URL"
                                                        placeholder="Enter Your Twitter URL"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <FormikControl
                                                        control="textfield"
                                                        name="facebookUrl"
                                                        label="Facebook URL"
                                                        placeholder="Enter Your Facebook URL"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <FormikControl
                                                        control="textfield"
                                                        name="youtube"
                                                        label="YouTube URL"
                                                        placeholder="Enter Your YouTube URL"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Box marginTop="20px" display="flex" justifyContent="space-between">
                                                <Button
                                                    className='btn btn-back'
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleBack}
                                                >
                                                    Back
                                                </Button>
                                                <Button
                                                    // type="submit"
                                                    className='btn btn-submit'
                                                    onClick={handleNext}
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={!dirty || !(isValid)}
                                                >
                                                    Next
                                                </Button>
                                            </Box>
                                        </>
                                    )}
                                    {activeStep === 4 && (
                                        <>
                                            <Typography variant="h4" align="center" marginBottom="30px">
                                                Membership Type
                                            </Typography>
                                            <FormikControl
                                                control="radiogroup"
                                                label="Membership Type"
                                                name="membershipType"
                                                options={[
                                                    { key: "Founder Member", value: "founder" },
                                                    { key: "Exclusive Member", value: "exclusive" },
                                                ]}
                                                placeholder="Select Membership Type"
                                                requiredField
                                                row
                                            />
                                            <Box marginTop="20px" display="flex" justifyContent="space-between">
                                                <Button
                                                    className='btn btn-back'
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleBack}
                                                >
                                                    Back
                                                </Button>
                                                <Button
                                                    //type="submit"
                                                    className='btn btn-submit'
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleNext}
                                                    disabled={(!dirty || !(isValid)) || values.membershipType.length === 0}
                                                >
                                                    Next
                                                </Button>
                                            </Box>
                                        </>
                                    )}
                                    {activeStep === 5 && (
                                        <>
                                            <MembershipContactPerson memberlist={memberlist} setMemberList={setMemberList} />


                                            {memberlist?.length > 0 ?
                                                <>
                                                    <br></br>
                                                    <RECAPTCHA
                                                        sitekey={msgTypes.GOOGLE_CAPTCHA_KEY}
                                                        ref={recaptcha}
                                                        onChange={onCaptchaSubmit}
                                                    />

                                                    <Box marginTop="20px" display="flex" justifyContent="space-between">
                                                        <Button
                                                            className='btn btn-back'
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={handleBack}
                                                        >
                                                            Back
                                                        </Button>
                                                        <Button
                                                            className='btn btn-submit'
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            disabled={isSubmitting && (!dirty || !(isValid) || memberlist.length === 0) || !captchaMatched}
                                                        >
                                                            Submit
                                                        </Button>

                                                    </Box>
                                                </>

                                                : ""}
                                        </>
                                    )}
                                </Form>
                            )}
                        </Formik>
                    </Paper>
                </Grid>
            </Box>
        </Box>
    );
};

export default MembershipForm;
