import React from 'react'

const GetFeatured = () => {
  return (
    <div>
        {/* <!--CTA One Start--> */}
        <section className="cta-one">
                    <div className="container">
                        <div className="cta-one__inner">
                            <div className="cta-one__bg-img ctaStyle"></div>
                            <div className="cta-one__content-box">
                                <div className="row">
                                    <div className="col-xl-6">
                                        <h3 className="cta-one__title">Get Featured in our
                                            <br></br> Newsletter
                                        </h3>
                                        <div className="cta-one__contact-box">
                                            <div className="icon">
                                                <span className="icon-phone"></span>
                                            </div>
                                            <div className="content">
                                                <p>Need help?</p>
                                                <h3><a href="tel:+971 54 522 3903">+971 54 522 3903</a></h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <form className="contact-form-validated faq-one__form" action="#">
                                            <div className="row">
                                                <div className="col-xl-6 col-lg-6">
                                                    <div className="faq-one__input-box">
                                                        <input type="text" name="name" placeholder="Name" required="" />
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-lg-6">
                                                    <div className="faq-one__input-box">
                                                        <input type="email" name="email" placeholder="Category" required="" />
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12">
                                                    <div className="faq-one__input-box">
                                                        <input type="text" name="text" placeholder="Message" required="" />
                                                    </div>
                                                </div>
                                                <div className=" col-xl-12">
                                                    <div className="faq-one__btn-box">
                                                        <button type="submit" className="thm-btn faq-one__btn">Submit
                                                            <span></span></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!--CTA One End--> */}
    </div>
  )
}

export default GetFeatured
