export default class ApiUrl {
    // public static baseUrl: string = 'http://192.168.1.38/1wn-api/public/api';
    // public static fileBaseUrl: string = 'http://192.168.1.38/1wn-api/public';

    // public static baseUrl: string = 'http://192.168.1.13:6000/api';
    // public static fileBaseUrl: string = 'http://192.168.1.13:6000/public';
   
    public static midUrl: string = '/api';
    public static baseUrl: string = 'https://1wnapi.csgtech.in/api' 
    public static fileBaseUrl: string = 'https://1wnapi.csgtech.in';

}