export const RouteType = {
    FILE: '/file',

    MEMBERSHIP_REGISTER: '/membership-register',
    EVENT_REGISTRATION: '/event-register',

    GET_COUNTRY: '/get-country',
    GET_STATE: '/get-state',
    GET_CITY: '/get-city',

    GET_EVENT: '/get-event',
}

