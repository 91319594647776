import { ContactPersonModel } from "./ContactPersonModel";


export class MembershipFormModel{
    contactPerson: ContactPersonModel[];

    companyName: string;
    companyEmail: string;
    primaryPhoneNumber: string;
    secondaryPhoneNumber: string;
    websiteUrl: string;
    accountingEmail: string;
    addressLine1: string;
    city: number;
    state: number;
    country: number;
    zipCode: string;
    companyDetails: string;
    instagramUrl: string;
    linkedinUrl: string;
    twitterUrl: string;
    facebookUrl: string;
    youtubeUrl: string;
    membershipType: string;

    constructor(){
        this.contactPerson = [];
        this.companyName="";
        this.companyEmail="";
        this.primaryPhoneNumber="";
        this.secondaryPhoneNumber="";
        this.websiteUrl="";
        this.accountingEmail="";
        this.addressLine1="";
        this.city=0;
        this.state=0;
        this.country=0;
        this.zipCode="";
        this.companyDetails="";
        this.instagramUrl="";
        this.linkedinUrl="";
        this.twitterUrl="";
        this.facebookUrl="";
        this.youtubeUrl="";
        this.membershipType=""
    }
}