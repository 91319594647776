import { Link } from "react-router-dom";
import Header from '../../sharedScreen/Header'
//import "react-multi-carousel/lib/styles.css";
import './ContactUs.scss';
import Footer from 'screens/sharedScreen/Footer';

const ContactUs = () => {

    return (
        <div>
            {/* <!--Page Header Start--> */}
            <section className="page-header member-section">
                <div className="page-header__bg contact-banner">
                </div>
                <div className="container">
                    <div className="page-header__inner">
                        <h2>Contact Us</h2>
                        <div className="thm-breadcrumb__box">
                            <ul className="thm-breadcrumb list-unstyled">
                                {/* <li><a href="index.html">Home</a></li> */}
                                <li><Link to="/">Home</Link></li>
                                <li><span className="icon-angle-left"></span></li>
                                <li>Contact Us</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* //   <!--Page Header End--> */}
            <section className="contact" id="contact">
                <div className="container">
                    <div className="heading text-center">
                        {/* <h2>Contact
                            <span> Us </span></h2> */}
                        <p>Embrace an invitation into a community marked by dynamic collaboration, robust efficiency,<br />and a client-centric ethos at One World Network.</p>
                    </div>
                    <div className='row contact-add-section'>
                        <div className='col-md-4'>
                            <div className='add-section'>
                                <div className='add--'>
                                    <div className='icon'>
                                        <i className="fas fa-map-marker-alt"></i>
                                    </div>
                                    <h3>Radisson Blu Plaza Hotel,<br />
                                        Delhi Airport, New Delhi -110037</h3>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='add-section'>
                                <div className='add--'>
                                    <div className='icon'>
                                        <i className="fas fa-map-marker-alt"></i>
                                    </div>
                                    <h3>IFZA Business Park,<br />
                                        Dubai Silicon Oasis, Dubai</h3>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='add-section'>
                                <div className='add--'>
                                    <div className='icon'>
                                        <i className="fas fa-map-marker-alt"></i>
                                    </div>
                                    <h3>511, 8W Building, DAFZA Dubai Airport Free Zone,<br />
                                        Dubai Airport, UAE .</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row contact-detail-sec">
                        <div className="col-md-5 position-relative">
                            <div className='contact-page'>
                                <div className="title">
                                    <h3>Contact detail</h3>
                                    <p> Becoming a member of One World Network <br /> means joining a community of dynamic, resilient, and results-driven freight forwarders. </p>
                                </div>
                                <div className="content">
                                    {/* <!-- Info-1 --> */}
                                    <div className="info">
                                        <div className='icon'><i className="fas fa-mobile-alt"></i></div>
                                        <h4 className="d-inline-block">For India and what’s app :
                                            <br />
                                            <span>+91-73037 33554</span></h4>
                                    </div>
                                    {/* <!-- Info-2 --> */}
                                    <div className="info">
                                        <div className='icon'><i className="fas fa-mobile-alt"></i></div>
                                        <h4 className="d-inline-block">For Dubai :
                                            <br />
                                            <span>+971-54 522 3903</span></h4>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="col-md-7">

                            <form className="form-margin">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <input type="text" className="form-control" placeholder="Name" />
                                    </div>
                                    <div className="col-sm-6">
                                        <input type="email" className="form-control" placeholder="Email" />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" className="form-control" placeholder="Phone" />
                                    </div>
                                    <div className="col-sm-6">
                                        {/* <label>Quantity of participants</label> */}
                                        <select class=" form-select-lg mb-3 form-select-contact">
                                            <option selected>Category</option>
                                            <option value="1">Membership Related Queries</option>
                                            <option value="2">Network Related Queries</option>
                                            <option value="3">Payment Related Queries</option>
                                            <option value="3">Conference Related Queries</option>
                                            <option value="3">Other</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <textarea className="textarea-contact" rows="2" id="comment" placeholder="Message"></textarea>
                                </div>
                                <div className="col-md-12">
                                    <div className="faq-one__btn-box contact-btn">
                                        <button type="submit" className="thm-btn faq-one__btn">Submit
                                            <span></span></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
}

export default ContactUs