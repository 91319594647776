import { Link } from 'react-router-dom';
import Header from '../../sharedScreen/Header'
import Footer from 'screens/sharedScreen/Footer';
import './AboutUs.scss';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const AboutUs = () => {


    const supplyChainResponsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 1700, min: 1200 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 1200, min: 992 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 992, min: 768 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 1
        }
    }

    return (
        <div>
            {/* <!--Page Header Start--> */}
            <section className="page-header">
                <div className="page-header__bg about-banner">
                </div>
                <div className="container">
                    <div className="page-header__inner">
                        <h2>About Us</h2>
                        <div className="thm-breadcrumb__box">
                            <ul className="thm-breadcrumb list-unstyled">
                                {/* <li><a href="index.html">Home</a></li> */}
                                <li><Link to="/">Home</Link></li>
                                <li><span className="icon-angle-left"></span></li>
                                <li>About Us</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--Page Header End--> */}

            {/* about one section */}
            <section id="ft-about-2" class="ft-about-section-2">
                <div class="container">
                    <div class="ft-about-content-2">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="ft-about-text-wrapper-2">
                                    <div class="ft-section-title-2 headline pera-content">
                                        <h2><span>1WN - </span>One World One Network
                                        </h2>
                                        <p>Welcome to 1WN - One World One Network , a global network curated to help elevate businesses & forge bonds in a secured environment, of its exclusive members from the logistics and freight forwarding industry. Founded with a vision to transform collaboration, 1WN unites a community of seasoned logistics professionals, cultivating a new era of operational efficiency and unwavering reliability. </p>
                                    </div>
                                    <div class="ft-about-feature-wrapper-2">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="ft-about-feature-list-item d-flex align-items-center">
                                                    <div
                                                        class="ft-about-feature-icon d-flex align-items-center justify-content-center">
                                                        <i class="fas fa-star-of-life"></i>
                                                    </div>
                                                    <div class="ft-about-feature-text headline pera-content">
                                                        <h3>Our Mission</h3>
                                                        <p>Committed to maximize your business growth.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="ft-about-feature-list-item d-flex align-items-center">
                                                    <div
                                                        class="ft-about-feature-icon d-flex align-items-center justify-content-center">
                                                        <i class="fas fa-snowflake"></i>
                                                    </div>
                                                    <div class="ft-about-feature-text headline pera-content">
                                                        <h3>Our Vision</h3>
                                                        <p>We aspire to create a world where global connectivity fuels sustainable growth, prosperity and harmony. We aim to overcome geographical barriers, stimulate innovation and uplift communities, thereby shaping a brighter future for generations to come.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="ft-about-img-2-wrapper position-relative">
                                    <span class="ft-about-shape1 position-absolute"><img src="assets/img/shape/ab-sh1.png"
                                        alt="" /></span>
                                    <span class="ft-about-shape2 position-absolute"><img src="images/resources/about-company.webp"
                                        alt="" /></span>
                                    <div class="ft-about-img-2">
                                        <img src="assets/img/about/ab2.jpg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* about one section */}

            {/* <!--About One Start--> */}
            <section className="about-one about-two">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6">
                            <div className="about-one__left">
                                <div className="section-title text-left">
                                    <div className="section-title__tagline-box">
                                        {/* <!-- <span className="section-title__tagline">About Us</span> --> */}
                                    </div>
                                    <h2 className="section-title__title">Our Legacy</h2>
                                </div>
                                <p className="about-one__text">Founded on the principles of collaboration and expertise, 1WN has emerged as a game-changer of the logistics-networking industry. We embarked upon our journey with an undeterred commitment and anchored our head office at the heart of UAE. With over 100 years of collective work experience, our management team consists of veterans who are well-known names of the logistics industry globally.</p>
                                <div className="about-one__list-box">
                                    <div className="about-one__shape-1">
                                        <img src="images/shapes/about-one-shape-1.png" alt="" />
                                    </div>
                                    <ul className="about-one__list list-unstyled">
                                        <li>
                                            <div className="about-one__icon">
                                                <span className="icon-conveyor-1"></span>
                                            </div>
                                            <h3>Delivering successthe <br /> through logistics</h3>
                                        </li>
                                        <li>
                                            <div className="about-one__icon">
                                                <span className="icon-clock"></span>
                                            </div>
                                            <h3>Logistics expertise for your<br /> competitive </h3>
                                        </li>
                                        <li>
                                            <div className="about-one__icon">
                                                <span className="icon-fragile"></span>
                                            </div>
                                            <h3>Streamliningm supply chain<br /> processes</h3>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="about-one__right wow fadeInRight" data-wow-delay="300ms">
                                <div className="about-one__img-box">
                                    <div className="about-one__img-1">
                                        <img src="images/resources/mission-img-1.png" alt="" />
                                    </div>
                                    <div className="about-one__img-2">
                                        <img src="images/resources/mission-img-2.png" alt="" />
                                    </div>
                                    <div className="about-one__trusted-box">
                                        <div className="about-one__trust-icon">
                                            <span className="icon-ionic-ios-people"></span>
                                        </div>
                                        <div className="about-one__trust-content">
                                            <div className="about-one__trust-count count-box">
                                                <h3 className="count-text" data-stop="6" data-speed="1500">00</h3>
                                                <span>k</span>
                                                <span className="about-one__trust-plus">+</span>
                                            </div>
                                            <p className="about-one__trust-text">Trusted Customer</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--About One End--> */}

            {/* about-us section */}
            <section id="ft-thx-about" class="ft-thx-about-section">
                <div class="container">
                    <div class="pr-cor-about-content">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="pr-cor-about-img-wrap position-relative">
                                    <div class="pr-cor-about-img-area">
                                        <img src="images/resources/benefit-1.webp" alt="" />
                                    </div>
                                    <div class="pr-cor-about-img-shape position-absolute">
                                        <img src="images/resources/ab-sh1.png" alt="" />
                                    </div>
                                    <div class="pr-cor-about-img-area2 position-absolute">
                                        <img src="images/resources/benefit-2.webp" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="pr-cor-about-text-wrap">
                                    <div class="pr-cor-section-title headline pera-content wow fadeInUp" data-wow-delay="0ms"
                                        data-wow-duration="1500ms">
                                        <h2>Unique Membership Benefits</h2>
                                        <p>1WN is an invitation into a community marked by dynamic collaboration, robust efficiency, and a client-centric ethos. Our blend of cutting-edge technology, an influential value system, seamless communication, innovative strategies for common business goals ensures a wholesome business experience. </p>
                                        <br />
                                        <p>Our exclusive members enjoy a range of benefits, special privileges and get exclusive invitations from time to time to network with a vibrant community of dynamic, result-oriented, influential freight forwarders from the world over.</p>
                                        <br />
                                        <p>Number of members per region or country shall be directly proportional to World EXIM Data. At any given instance, any country membership shall not exceed 10% of total membership count. </p>
                                    </div>
                                    {/* <div class="pr-cor-about-feature-area">
                                        <div class="pr-cor-about-feature-list d-flex align-items-center wow fadeInUp"
                                            data-wow-delay="100ms" data-wow-duration="1500ms">
                                            <div class="pr-cor-about-ft-icon d-flex align-items-center justify-content-center">
                                                <i class="flaticon-worldwide"></i>
                                            </div>
                                            <div class="pr-cor-about-ft-text headline pera-content">
                                                <h3>Fast Worldwide Delivery</h3>
                                                <p>Our exclusive members enjoy a range of benefits, special privileges and get exclusive invitations from time to time to network with a vibrant community of dynamic, result-oriented, influential freight forwarders from the world over.</p>
                                            </div>
                                        </div>
                                        <div class="pr-cor-about-feature-list d-flex align-items-center wow fadeInUp"
                                            data-wow-delay="200ms" data-wow-duration="1500ms">
                                            <div class="pr-cor-about-ft-icon d-flex align-items-center justify-content-center">
                                                <i class="flaticon-shield-1"></i>
                                            </div>
                                            <div class="pr-cor-about-ft-text headline pera-content">
                                                <h3>Safe And Secure Delivery</h3>
                                                <p>Number of members per region or country shall be directly proportional to World EXIM Data. At any given instance, any country membership shall not exceed 10% of total membership count. </p>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* about-us section */}


            {/* <!--Testimonial One Start--> */}
            <section className="testimonial-one">
                <div className="container">
                    <div className="testimonial-one__inner">
                        <div className="testimonial-one__img-one">
                            <img src="images/testimonial/testimanoil-img-1.png" alt=""></img>
                        </div>
                        <div className="section-title text-center">
                            <div className="border-center section-title__tagline-box">
                                <span className="section-title__tagline">clients testimonial</span>
                            </div>
                            <h2 className="section-title__title">Member's success story<br></br> in their words</h2>
                        </div>
                        <div className="row">
                            <div className="col-xl-5"></div>
                            <div className="col-xl-7 col-lg-9">
                                <div className="testimonial-one__right">
                                    {/* <div className="thm-swiper__slider swiper-container" data-swiper-options='{
                                    "slidesPerView": 1, 
                                    "spaceBetween": 0,
                                    "speed": 2000,
                                    "loop": true,
                                    "pagination": {
                                        "el": ".swiper-dot-style1",
                                        "type": "bullets",
                                        "clickable": true
                                    },
                
                                    
                
                                    "navigation": {
                                        "nextEl": ".swiper-button-prev1",
                                        "prevEl": ".swiper-button-next1"
                                    },
                                    "autoplay": { "delay": 9000 },
                                    "breakpoints": {
                                            "0": {
                                                "spaceBetween": 0,
                                                "slidesPerView": 1
                                            },
                                            "375": {
                                                "spaceBetween": 0,
                                                "slidesPerView": 1
                                            },
                                            "575": {
                                                "spaceBetween": 0,
                                                "slidesPerView": 1
                                            },
                                            "768": {
                                                "spaceBetween": 30,
                                                "slidesPerView": 1
                                            },
                                            "992": {
                                                "spaceBetween": 30,
                                                "slidesPerView": 1
                                            },
                                            "1200": {
                                                "spaceBetween": 30,
                                                "slidesPerView":1
                                            },
                                            "1320": {
                                                "spaceBetween": 30,
                                                "slidesPerView":1
                                            }
                                        }
                                }'> */}
                                    <Carousel arrows={true} responsive={supplyChainResponsive} infinite={true} showDots={false} autoPlay={true}>
                                        <div className="swiper-wrapper">
                                            {/* <!--Testimonial One Single Start--> */}
                                            <div className="swiper-slide">
                                                <div className="testimonial-one__single">
                                                    <div className="testimonial-one__quote">
                                                        <span className="icon-quote"></span>
                                                    </div>
                                                    {/* <!-- <div className="testimonial-one__client-img">
                                                    <img src="images/testimonial/testimonial-1-1.jpg" alt=""></img>
                                                </div> --> */}
                                                    <div className="testimonial-one__ratting">
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star last-star"></span>
                                                    </div>
                                                    <h3 className="testimonial-one__client-name">Nafiz Bhuiyan</h3>
                                                    <p className="testimonial-one__client-sub-title">Manager</p>
                                                    <p className="testimonial-one__text">Logistic service involves the planning
                                                        implementation an and control of the efficient and effective
                                                        movement and storage of goods and materials Logistic service
                                                        involves the planning implementation and control</p>
                                                </div>
                                            </div>
                                            {/* <!--Testimonial One Single End-->
                                        <!--Testimonial One Single Start--> */}
                                            <div className="swiper-slide">
                                                <div className="testimonial-one__single">
                                                    <div className="testimonial-one__quote">
                                                        <span className="icon-quote"></span>
                                                    </div>
                                                    {/* <!-- <div className="testimonial-one__client-img">
                                                    <img src="images/testimonial/testimonial-1-2.jpg" alt=""></img>
                                                </div> --> */}
                                                    <div className="testimonial-one__ratting">
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star last-star"></span>
                                                    </div>
                                                    <h3 className="testimonial-one__client-name">Robert Son</h3>
                                                    <p className="testimonial-one__client-sub-title">Manager</p>
                                                    <p className="testimonial-one__text">Logistic service involves the planning
                                                        implementation an and control of the efficient and effective
                                                        movement and storage of goods and materials Logistic service
                                                        involves the planning implementation and control</p>
                                                </div>
                                            </div>
                                            {/* <!--Testimonial One Single End-->
                                        <!--Testimonial One Single Start--> */}
                                            <div className="swiper-slide">
                                                <div className="testimonial-one__single">
                                                    <div className="testimonial-one__quote">
                                                        <span className="icon-quote"></span>
                                                    </div>
                                                    {/* <!-- <div className="testimonial-one__client-img">
                                                    <img src="images/testimonial/testimonial-1-3.jpg" alt=""></img>
                                                </div> --> */}
                                                    <div className="testimonial-one__ratting">
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star last-star"></span>
                                                    </div>
                                                    <h3 className="testimonial-one__client-name">Harbert Spin</h3>
                                                    <p className="testimonial-one__client-sub-title">Manager</p>
                                                    <p className="testimonial-one__text">Logistic service involves the planning
                                                        implementation an and control of the efficient and effective
                                                        movement and storage of goods and materials Logistic service
                                                        involves the planning implementation and control</p>
                                                </div>
                                            </div>
                                            {/* <!--Testimonial One Single End-->
                                        <!--Testimonial One Single Start--> */}
                                            <div className="swiper-slide">
                                                <div className="testimonial-one__single">
                                                    <div className="testimonial-one__quote">
                                                        <span className="icon-quote"></span>
                                                    </div>
                                                    {/* <!-- <div className="testimonial-one__client-img">
                                                    <img src="images/testimonial/testimonial-1-4.jpg" alt=""></img>
                                                </div> --> */}
                                                    <div className="testimonial-one__ratting">
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star last-star"></span>
                                                    </div>
                                                    <h3 className="testimonial-one__client-name">Mainto Vula</h3>
                                                    <p className="testimonial-one__client-sub-title">Manager</p>
                                                    <p className="testimonial-one__text">Logistic service involves the planning
                                                        implementation an and control of the efficient and effective
                                                        movement and storage of goods and materials Logistic service
                                                        involves the planning implementation and control</p>
                                                </div>
                                            </div>
                                            {/* <!--Testimonial One Single End--> */}
                                        </div>
                                    </Carousel>
                                </div>
                            </div>

                            {/* </div> */}
                        </div>

                        <div className="testimonial-one__dot-style">
                            <div className="swiper-dot-style1"></div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--Testimonial One End--> */}

            {/* <!--Why Are We Start--> */}
            <div className='our-mission-footer-form'>
                <section className="why-are-we ">
                    <div className="container">
                        <div className='row'>
                            <div className='col-12'>
                                <div class="section-title text-center">
                                    <div class="border-center section-title__tagline-box">
                                        <span class="section-title__tagline">Join 1WN</span>
                                    </div>
                                    <h2 class="section-title__title">This ambitious vision is only achievable by</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4">
                                <div className="why-are-we__left">
                                    <div className="why-are-we__img">
                                        <img src="images/resources/global.png" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8">
                                <div className="why-are-we__right">

                                    {/* <div className="section-title text-left">
                                        <div className="section-title__tagline-box">
                                            <span className="section-title__tagline">This ambitious vision is only achievable by
                                                working </span>
                                        </div>
                                        <h2 className="section-title__title">Join 1WN
                                        </h2>
                                    </div> */}
                                    <p className="counter-one__right-text">As we continue to design this dynamic network we are delighted to invite you to transcend boundaries with the next-level networking experience. Join us and become an integral part of a community that redefines logistics, fosters collaboration, and shapes the future of global trade. Let’s connect and navigate through the complexities of the logistics industry and together create One World One Network!
                                    </p>
                                    <div className="why-are-we__img-2">
                                        <img src="images/resources/why-we-img-2.png" alt="" />
                                        <div className="why-are-we__year wow fadeInLeft" data-wow-delay="300ms">
                                            <h3>PROFESSIONAL BUSINESS SUPPORT</h3>
                                            <p>Association service involves the planning, implementation, and control of the efficient and effective movement and storage</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {/* <!--Why Are We End--> */}

            {/* <!--CTA One Start--> */}
            {/* <section className="cta-one cta-two">
            <div className="container">
                <div className="cta-one__inner">
                    <div className="cta-one__bg-img"></div>
                    <div className="cta-one__content-box">
                        <h3 className="cta-one__title">Join the One World Network
                        </h3>
                        <p className="counter-one__right-text text-white">As we continue to build a network that transcends
                            boundaries,
                            we invite you to be part of the One World Network experience. Join us and become an integral
                            part of a community that redefines logistics, fosters collaboration, and shapes the future
                            of global trade. Together, we navigate the complexities of the logistics landscape, creating
                            a world where connectivity knows no limits.
                        </p>
                    </div>
                </div>
            </div>
        </section> */}
            {/* <!--CTA One End--> */}

        </div>
    )
}

export default AboutUs;
