import SearchPopup from 'screens/sharedScreen/SearchPopup';
import Header from '../../sharedScreen/Header'
//import "react-multi-carousel/lib/styles.css";
import './Home.scss';
import Footer from 'screens/sharedScreen/Footer';
import FixedButtons from 'screens/sharedScreen/FixedButtons';
import MobileNavWrapper from 'screens/sharedScreen/MobileNavWrapper';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom'

const Home = () => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 1700, min: 1200 },
            items: 8
        },
        desktop: {
            breakpoint: { max: 1200, min: 992 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 992, min: 768 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 2
        }
    };

    const ourTeamResponsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 1700, min: 1200 },
            items: 3
        },
        desktop: {
            breakpoint: { max: 1200, min: 992 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 992, min: 768 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 1
        }
    };

    const supplyChainResponsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 1700, min: 1200 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 1200, min: 992 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 992, min: 768 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 1
        }
    }

    //   useEffect(()=> {
    //     swiper.init()
    // }, [])

    return (
        <div>
            {/* <div className="page-wrapper">
                <div className="stricky-header stricked-menu main-menu">
                    <div className="sticky-header__content"></div>
                </div> */}

            {/* Banner One Start */}
            <section className="banner-one">
                <div className="banner-one__shape-1 float-bob-y bannerStyle">
                </div>
                <div className="container h-100">
                    <div className="row h-100">
                        <div className="col-xl-12 h-100">
                            <div className="banner-one__left wow fadeInLeft" data-wow-delay="300ms">
                                <div className="banner-one__sub-title-box">
                                    <h2 className="banner-one__title text-center">Step into <br />
                                        <div className='d-flex justify-content-center flex-wrap'>
                                            <span className="banner-text-color">One&nbsp;</span>
                                            World<span className="banner-text-color">&nbsp;One&nbsp;</span>Network
                                        </div>
                                    </h2>
                                    <h5 className="banner-one__sub-title">"Committed to maximize your business growth"</h5>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-xl-10">
                                <div className="banner-one__right wow fadeInRight" data-wow-delay="400ms">
                                    <div className="banner-one__img-box">
                                        <div className="banner-one__img">
                                            <img src=" images/resources/banner-img-1.jpg" alt=""></img>
                                        </div>
                                        <div className="banner-one__content">
                                            <div className="banner-one__call">
                                                <div className="banner-one__call-icon">
                                                    <span className="icon-phone"></span>
                                                </div>
                                                <div className="banner-one__call-number">
                                                    <p>Need help?</p>
                                                    <h5><a href="tel:+971 54 522 3903">+971 54 522 3903</a></h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="banner-one__img-two">
                                            <img src="images/resources/banner-img-2.png" alt=""></img>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                    </div>
                </div>
            </section>
            {/* Banner One End */}

            {/* About One Start */}
            <section className="about-one">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6">
                            <div className="about-one__left">
                                <div className="section-title text-left">
                                    <div className="section-title__tagline-box">
                                        <span className="section-title__tagline">// About Us</span>
                                    </div>
                                    <h2 className="section-title__title">Seamless Connection for your <br></br> business needs</h2>
                                </div>
                                <p className="about-one__text"><strong>1WN</strong> unites a community of seasoned logistics professionals, cultivating a new era of operational efficiency and unwavering reliability. </p>
                                <div className="about-one__list-box">
                                    <div className="about-one__shape-1">
                                        <img src="images/shapes/about-one-shape-1.png" alt=""></img>
                                    </div>
                                    <ul className="about-one__list list-unstyled">
                                        <li>
                                            <div className="about-one__icon">
                                                <span className="icon-conveyor-1"></span>

                                            </div>
                                            <h3>Delivering success <br></br> through Network</h3>
                                        </li>
                                        <li>
                                            <div className="about-one__icon">
                                                <span className="icon-clock"></span>

                                            </div>
                                            <h3>Association expertise for your<br></br> Business </h3>
                                        </li>
                                        <li>
                                            <div className="about-one__icon">
                                                <span className="icon-fragile"></span>
                                            </div>
                                            <h3>Streamlining supply chain<br></br> processes</h3>
                                        </li>
                                    </ul>
                                </div>
                                <div className="about-one__btn-box">
                                    <a href="about.html" className="thm-btn about-one__btn">Read more<span></span></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="about-one__right wow fadeInRight" data-wow-delay="300ms">
                                <div className="about-one__img-box">
                                    <div className="about-one__img-1">
                                        <img src="images/resources/about-img-2.png" alt=""></img>
                                    </div>
                                    <div className="about-one__img-2">
                                        <img src="images/resources/about-img-1.png" alt=""></img>
                                    </div>
                                    <div className="about-one__trusted-box">
                                        <div className="about-one__trust-icon">
                                            <span className="icon-ionic-ios-people"></span>
                                        </div>
                                        <div className="about-one__trust-content">
                                            <div className="about-one__trust-count count-box">
                                                <h3 className="count-text" data-stop="1" data-speed="1500">00</h3>
                                                <span>k</span>
                                                <span className="about-one__trust-plus">+</span>
                                            </div>
                                            <p className="about-one__trust-text">Trusted Members</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* About One End */}


            {/* Services Two Start */}
            <section className="services-two serviceTwoStyle">
                <div className="container">
                    <div className="section-title text-center">
                        <div className="border-center section-title__tagline-box">
                            <span className="section-title__tagline">Features</span>
                        </div>
                        <h2 className="section-title__title">Powerful Features of 1WN</h2>
                    </div>
                    <div className="row">
                        {/* Services Two Single Start */}
                        <div className="col-xl-4 col-lg-4 wow fadeInLeft" data-wow-delay="100ms">
                            <Link to="/membership-benifits">
                                <div className="services-two__single">
                                    <div className="services-two__icon">
                                        <img src="images/feture-icon/expert.png" alt="" />
                                    </div>
                                    <h3 className="services-two__title"><a href="#">Global Experts</a></h3>
                                    <p className="services-two__text">Gain unparalleled access to insights and guidance from our esteemed Board of Advisors, composed of influential figures and thought leaders in the logistics domain.</p>
                                </div>
                            </Link>
                        </div>
                        {/* <!--Services Two Single End--> */}
                        {/* <!--Services Two Single Start--> */}
                        <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="200ms">
                            <Link to="/membership-benifits">
                                <div className="services-two__single">
                                    <div className="services-two__icon">
                                        <img src="images/feture-icon/members.png" alt="" />
                                    </div>
                                    <h3 className="services-two__title"><a href="#">Limited Membership</a></h3>
                                    <p className="services-two__text">Emphasizing exclusivity and strategic alignment, our membership allocation follows meticulous criteria based on EXIM Statistics.</p>
                                </div>
                            </Link>
                        </div>
                        {/* <!--Services Two Single End--> */}
                        {/* <!--Services Two Single Start--> */}
                        <div className="col-xl-4 col-lg-4 wow fadeInRight" data-wow-delay="300ms">
                            <Link to="/membership-benifits">
                                <div className="services-two__single">
                                    <div className="services-two__icon">
                                        <img src="images/feture-icon/meeting-room.png" alt="" />
                                    </div>
                                    <h3 className="services-two__title"><a href="#"> Personalized Meetings</a></h3>
                                    <p className="services-two__text">Forge meaningful connections and collaborations through regular one-on-one meetings with fellow network members.</p>
                                </div>
                            </Link>
                        </div>
                        {/* <!--Services Two Single End--> */}
                        {/* <!--Services Two Single Start--> */}
                        <div className="col-xl-4 col-lg-4 wow fadeInRight" data-wow-delay="300ms">
                            <Link to="/membership-benifits">
                                <div className="services-two__single">
                                    <div className="services-two__icon">
                                        <img src="images/feture-icon/cyber-security.png" alt="" />
                                    </div>
                                    <h3 className="services-two__title"><a href="#">Financial Security</a></h3>
                                    <p className="services-two__text">Mitigate risks and instill confidence in business transactions with our robust Financial Protection Program</p>
                                </div>
                            </Link>
                        </div>
                        {/* <!--Services Two Single End--> */}
                        {/* <!--Services Two Single Start--> */}
                        <div className="col-xl-4 col-lg-4 wow fadeInRight" data-wow-delay="300ms">
                            <Link to="/membership-benifits">
                                <div className="services-two__single">
                                    <div className="services-two__icon">
                                        <img src="images/feture-icon/web-design.png" alt="" />
                                    </div>
                                    <h3 className="services-two__title"><a href="#">Customized Tools</a>
                                    </h3>
                                    <p className="services-two__text">Empower your business with cutting-edge marketing and PR digital tools tailored to the unique needs of logistics operations.</p>
                                </div>
                            </Link>
                        </div>
                        {/* <!--Services Two Single End--> */}
                        {/* <!--Services Two Single Start--> */}
                        <div className="col-xl-4 col-lg-4 wow fadeInRight" data-wow-delay="300ms">
                            <Link to="/membership-benifits">
                                <div className="services-two__single">
                                    <div className="services-two__icon">
                                        <img src="images/feture-icon/agreement.png" alt="" />
                                    </div>
                                    <h3 className="services-two__title"><a href="#">Trusted Partners</a>
                                    </h3>
                                    <p className="services-two__text">Collaborate seamlessly with a global network of well-established and track-proven freight forwarding partners.</p>
                                </div>
                            </Link>
                        </div>
                        <div className='col-12 mb-5 pb-3 d-flex justify-content-center'>
                            <div class="faq-one__btn-box contact-btn"><Link className="thm-btn faq-one__btn" to="/membership-benifits">View all <span></span></Link></div>
                        </div>
                        {/* <!--Services Two Single End--> */}
                    </div>
                </div>
            </section>
            {/* <!--Services Two End--> */}

            {/* <!-- sunil-arora Start --> */}
            <div class="arora-padding">
                <section id="ft-team-details" class="ft-team-details-section">
                    <div class="container-fluid">
                        <div class="ft-team-details-content position-relative">
                            <span class="design-shape position-absolute"><img src="images/resources/arora-img-bg.png"
                                alt="" /></span>
                            <div class="row">
                                <div class="col-lg-4 team-details">
                                    <div class="ft-team-details-img position-relative">
                                        <img src="images/resources/sunil-arora.png" alt="" />
                                    </div>
                                </div>
                                <div class="col-lg-8">
                                    <div class="ft-team-details-text-wrapper headline pera-content">
                                        <div class="ft-team-details-text">
                                            <h3>MR. SUNIL ARORA <span class="ceo-color">(CEO)</span> </h3>
                                            <p> Mr. Sunil Arora is a seasoned professional,
                                                logistician with 35 years of
                                                comprehensive
                                                expertise in freight forwarding, customs clearance within the Cargo
                                                Industry. He
                                                has held
                                                various prestigious positions such as President of the ACAAI (Air Cargo
                                                Agents
                                                Association of India) and the Air Cargo Club of Delhi, alongside serving as
                                                a
                                                Senior
                                                Advisor to the Delhi Customs Broker Association. </p>
                                            <br />
                                            <p>Recognized for his contributions, he was honoured with the "Mentor of the
                                                Year -
                                                2018" award by India Cargo Awards and the "Logistics Industry Ambassador
                                                Award - 2023." </p>
                                            <br />
                                            <p>Additionally, Mr. Sunil holds an MBA from IIMM, the Indian Institute of
                                                Marketing
                                                Management, and has undergone Basic & Advanced cargo training from IATA
                                                (International Air Transport Association), Geneva. He also possesses a CAT3
                                                -
                                                DGR
                                                (Dangerous Goods Regulations) certification, further enhancing his expertise
                                                in
                                                the field. </p>
                                            <br />
                                            <p class="peragraph-sunil">Mr. Sunil has actively worked with MoCA (Ministry of
                                                Civil Aviation) & MOC
                                                (Ministry of
                                                Commerce) on Industry Issues for over decades. He is part of all Logistics
                                                and
                                                infrastructure committees of National Organizations like CII (Confederation
                                                of
                                                Indian
                                                Industry), FICCI (Federation of Indian Chambers of Commerce and Industry),
                                                PHD
                                                Chambers of Commerce (PHD Chamber of Commerce and Industry). He is also an
                                                active
                                                member of the committee interacting with DGCA (Directorate General of Civil
                                                Aviation),
                                                BCAS (Bureau of Civil Aviation Security), and the Ministry of Commerce,
                                                Govt. of
                                                India. He
                                                has been actively involved at all senior level meetings with BAR (Board of
                                                Airline
                                                Representatives) - Airlines, Airport Custodians AERA (Airports Economic
                                                Regulatory
                                                Authority). </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {/* <!-- sunil-arora End --> */}

            {/* <!--Why Choose One Start--> */}
            {/* <section className="why-choose-one">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6">
                                <div className="why-choose-one__left">
                                    <div className="section-title text-left">
                                        <div className="section-title__tagline-box">
                                            <span className="section-title__tagline">// Why Chose us</span>
                                        </div>
                                        <h2 className="section-title__title">Delivering excellence every a time Express Network
                                        </h2>
                                    </div>
                                    <p className="why-choose-one__text">Construction is an essential industry that involves building
                                        adesigning the an structures such as buildings roads, bridges Construction is an essent
                                        industry that involves building adesigning the a structures such </p>
                                    <div className="why-choose-one__btn-box">
                                        <a href="about.html" className="thm-btn why-choose-one__btn">Read more<span></span></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6">
                                <div className="why-choose-one__right">
                                    <div className="why-choose-one__img wow slideInRight" data-wow-delay="100ms"
                                        data-wow-duration="2500ms">
                                        <img src="images/resources/why-choose-img-1.png" alt=""></img>
                                        <div className="why-choose-one__delivery-box">
                                            <div className="icon">
                                                <i className="far fa-handshake"></i>
                                            </div>
                                            <p>Emphasizes<br></br> Working Together</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
            {/* <!--Why Choose One End--> */}

            {/* <!--Project One Start--> */}
            <section className="project-one d-none">
                <div className="container">
                    <div className="section-title text-center">
                        <div className="section-title__tagline-box">
                            <span className="section-title__tagline">Our Verticals</span>
                        </div>
                        <h2 className="section-title__title">Our Strength lies in a <br></br>Robust Membership</h2>
                        <p>At 1WN, our strength lies in a robust membership base and a solid<br></br> infrastructure that forms
                            the
                            backbone of our pro-active, cooperative,<br></br> and formidable network of freight forwarders.</p>
                    </div>
                    <div className="row masonary-layout">
                        {/* <!--Project One Single Start--> */}
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <div className="project-one__single">
                                <div className="project-one__img-box">
                                    <div className="project-one__img">
                                        <img src="images/project/global-1.webp" alt=""></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--Project One Single End--> */}
                        {/* <!--Project One Single Start--> */}
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <div className="project-one__single">
                                <div className="project-one__img-box">
                                    <div className="project-one__img">
                                        <img src="images/project/global-2.webp" alt=""></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--Project One Single End--> */}
                        {/* <!--Project One Single Start--> */}
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <div className="project-one__single">
                                <div className="project-one__img-box">
                                    <div className="project-one__img">
                                        <img src="images/project/global-3.webp" alt=""></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--Project One Single End--> */}
                        {/* <!--Project One Single Start--> */}
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <div className="project-one__single">
                                <div className="project-one__img-box">
                                    <div className="project-one__img">
                                        <img src="images/project/global-4.webp" alt=""></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--Project One Single End--> */}
                        {/* <!--Project One Single Start--> */}
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <div className="project-one__single">
                                <div className="project-one__img-box">
                                    <div className="project-one__img">
                                        <img src="images/project/global-5.webp" alt=""></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--Project One Single End--> */}
                        {/* <!--Project One Single Start--> */}
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <div className="project-one__single">
                                <div className="project-one__img-box">
                                    <div className="project-one__img">
                                        <img src="images/project/global-6.webp" alt=""></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--Project One End--> */}

            <div className="team-area style-two">
                <div className="vertical-bg">
                    <div className="container-sm">
                        <div className="row">
                            <div className="col-xl-5 mb-5 d-table">
                                <div className="dreamit-section-title bg-bar2 d-table-cell align-middle pb-45 wow fadeInDown animated teamAreaStyle"
                                    data-wow-delay=".8s">
                                    <h4>OUR VERTICALS</h4>
                                    <h2 className="section-title__title text-white">Our Strength Lies In A <br></br> Robust Membership</h2>
                                    <p className="text-white">At 1WN, our strength lies in a robust membership base and a
                                        solid<br></br>
                                        infrastructure
                                        that
                                        forms
                                        the
                                        backbone of our pro-active, cooperative,<br></br> and formidable network of freight
                                        forwarders and.</p>
                                </div>
                            </div>
                            <div className='col-xl-7'>
                                <div className="row justify-content-center">
                                    <div className="col-md-3 col-6">
                                        <Link to="/verticals" activeClassName="active-link">
                                            <div className="dreamit-team-single-box wow fadeInLeft animated" data-wow-delay=".8s"
                                            //  style="visibility: visible;/* animation-delay: 0.8s; *//* animation-name: fadeInLeft; */"
                                            >
                                                <div className="dreamit-team-thumb">
                                                    <img src="images/project/global-1.png" alt=""></img>
                                                </div>
                                                <div className="team-content-socials">
                                                    <div className="team-content-socails-inner">
                                                        <p className="text-white">Global</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-md-3 col-6">
                                        <Link to="/verticals" activeClassName="active-link">
                                            <div className="dreamit-team-single-box wow fadeInRight animated teamAreaStyle2" data-wow-delay=".8s">
                                                <div className="dreamit-team-thumb">
                                                    <img src="images/project/global-6.png" alt=""></img>
                                                </div>
                                                <div className="team-content-socials">
                                                    <div className="team-content-socails-inner">
                                                        <p className="text-white">Temp</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-md-3 col-6">
                                        <Link to="/verticals" activeClassName="active-link">
                                            <div className="dreamit-team-single-box wow fadeInRight animated teamAreaStyle2" data-wow-delay=".8s">
                                                <div className="dreamit-team-thumb">
                                                    <img src="images/project/global-7.png" alt=""></img>
                                                </div>
                                                <div className="team-content-socials">
                                                    <div className="team-content-socails-inner">
                                                        <p className="text-white">NVOCC</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-md-3 col-6">
                                        <Link to="/verticals" activeClassName="active-link">
                                            <div className="dreamit-team-single-box wow fadeInUp animated teamAreaStyle1" data-wow-delay=".8s"
                                            >
                                                <div className="dreamit-team-thumb">
                                                    <img src="images/project/global-3.png" alt=""></img>
                                                </div>
                                                <div className="team-content-socials">
                                                    <div className="team-content-socails-inner">
                                                        <p className="text-white">Projects</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-md-3 col-6">
                                        <Link to="/verticals" activeClassName="active-link">
                                            <div className="dreamit-team-single-box wow fadeInRight animated teamAreaStyle2" data-wow-delay=".8s">
                                                <div className="dreamit-team-thumb">
                                                    <img src="images/project/global-5.png" alt=""></img>
                                                </div>
                                                <div className="team-content-socials">
                                                    <div className="team-content-socails-inner">
                                                        <p className="text-white">D. Goods</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-md-3 col-6">
                                        <Link to="/verticals" activeClassName="active-link">
                                            <div className="dreamit-team-single-box wow fadeInRight animated teamAreaStyle2" data-wow-delay=".8s">
                                                <div className="dreamit-team-thumb">
                                                    <img src="images/project/global-4.png" alt=""></img>
                                                </div>
                                                <div className="team-content-socials">
                                                    <div className="team-content-socails-inner">
                                                        <p className="text-white">Express</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-md-3 col-6">
                                        <Link to="/verticals" activeClassName="active-link">
                                            <div className="dreamit-team-single-box wow fadeInUp animated teamAreaStyle1" data-wow-delay=".8s"
                                            >
                                                <div className="dreamit-team-thumb">
                                                    <img src="images/project/global-2.png" alt=""></img>
                                                </div>
                                                <div className="team-content-socials">
                                                    <div className="team-content-socails-inner">
                                                        <p className="text-white">Personal</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!--Brand One Start--> */}
            <section className="brand-one">
                <div className="container">
                    {/* <div className="thm-swiper__slider swiper-container" data-swiper-options='{"spaceBetween": 100,
                "slidesPerView": 5,
                "loop": true,
                "navigation": {
                    "nextEl": "#brand-one__swiper-button-next",
                    "prevEl": "#brand-one__swiper-button-prev"
                },
                "autoplay": { "delay": 5000 },
                "breakpoints": {
                    "0": {
                        "spaceBetween": 30,
                        "slidesPerView": 1
                    },
                    "375": {
                        "spaceBetween": 30,
                        "slidesPerView": 1
                    },
                    "575": {
                        "spaceBetween": 30,
                        "slidesPerView": 2
                    },
                    "767": {
                        "spaceBetween": 50,
                        "slidesPerView": 3
                    },
                    "991": {
                        "spaceBetween": 50,
                        "slidesPerView": 4
                    },
                    "1199": {
                        "spaceBetween": 100,
                        "slidesPerView": 5
                    },
                    "1199": {
                        "spaceBetween": 100,
                        "slidesPerView": 5
                    }
                }}'>
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-1.png" alt=""></img>
                                </div>
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-2.png" alt=""></img>
                                </div>
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-3.png" alt=""></img>
                                </div>
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-4.png" alt=""></img>
                                </div>
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-5.png" alt=""></img>
                                </div>
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-1.png" alt=""></img>
                                </div>
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-2.png" alt=""></img>
                                </div>
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-3.png" alt=""></img>
                                </div>
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-4.png" alt=""></img>
                                </div>
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-5.png" alt=""></img>
                                </div>
                            </div>
                        </div> */}
                    <h2
                        className
                        =
                        "brand-one__text count-box section-title__title"
                    > 1WN &nbsp;Welcomes you to our growing<br></br> global network in <span
                        className
                        =
                        "count-text"
                        data-stop
                        =
                        "40"
                        data-speed
                        =
                        "1500"
                    >00</span><span>+</span> Countries</h2>
                    <Carousel arrows={true} responsive={responsive} showDots={false} autoPlay={true} transitionDuration={200} infinite={true}>

                        <div className="swiper-slide">
                            <img src="images/flags/flag1.jpg" alt=""></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/flags/flag2.jpg" alt=""></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/flags/flag3.jpg" alt=""></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/flags/flag4.jpg" alt=""></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/flags/flag5.jpg" alt=""></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/flags/flag6.jpg" alt=""></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/flags/flag1.jpg" alt=""></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/flags/flag2.jpg" alt=""></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/flags/flag3.jpg" alt=""></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/flags/flag4.jpg" alt=""></img>
                        </div>
                    </Carousel>;
                </div>
            </section>
            {/* <!--Brand One End--> */}

            {/* <!--Our Vision--> */}
            <section className="process-one">
                <div className="container">
                    <div className="section-title text-center">
                        <div className="border-center section-title__tagline-box">
                            <span className="section-title__tagline">Why Choose us</span>
                        </div>
                        <h2 className="section-title__title">We aspire to create a world where...</h2>
                        <p>global connectivity fuels sustainable growth, prosperity and harmony. We aim to overcome
                            geographical barriers, stimulate innovation<br></br> and uplift communities, thereby shaping a
                            brighter
                            future for generations to come.</p>
                    </div>
                    <div className="row">
                        {/* <!--Process One Single Start--> */}
                        <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="100ms">
                            <div className="process-one__single">
                                <div className="process-one__shape-1">
                                    <img src="images/shapes/process-one-shape-1.png" alt=""></img>
                                </div>
                                <div className="process-one__count"></div>
                                <div className="process-one__icon-and-text">
                                    <p>Membership with One World Network is your passport to business expansion. By
                                        fostering collaboration within our global community, we create a unified force,
                                        empowering you to develop new partnerships and compete effectively against
                                        multinational corporations.</p>
                                </div>
                            </div>
                        </div>
                        {/* <!--Process One Single End--> */}
                        {/* <!--Process One Single Start--> */}
                        <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="200ms">
                            <div className="process-one__single">
                                <div className="process-one__shape-1">
                                    <img src="images/shapes/process-one-shape-1.png" alt=""></img>
                                </div>
                                <div className="process-one__count"></div>
                                {/* <!-- <h3 className="process-one__title">Reliable Distribution</h3> --> */}
                                <div className="process-one__icon-and-text">
                                    {/* <!-- <div className="icon">
                                    <span className="icon-shipping"></span>
                                </div> --> */}
                                    <p>Our stringent enrolment process ensures a network of esteemed members. Prospective
                                        companies undergo a thorough evaluation, showcasing strong references, bank credit
                                        ratings, and a commitment to professionalism. We value integrity, positive
                                        reputations, and leadership prowess.</p>
                                </div>
                            </div>
                        </div>
                        {/* <!--Process One Single End-->
                    <!--Process One Single Start--> */}
                        <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="300ms">
                            <div className="process-one__single">
                                <div className="process-one__count"></div>
                                {/* <!-- <h3 className="process-one__title">Fast and reliable logistics</h3> --> */}
                                <div className="process-one__icon-and-text">
                                    {/* <!-- <div className="icon">
                                    <span className="icon-postbox"></span>
                                </div> --> */}
                                    <p>We are dedicated to maintaining the highest standards in the Network industry. Our
                                        commitment translates into a professional network where each member benefits from
                                        our rigorous approach, ensuring optimal outcomes for your business.</p>
                                </div>
                            </div>
                        </div>
                        {/* <!--Process One Single End--> */}
                    </div>
                </div>
            </section>
            {/* <!--Our Vision--> */}

            {/* <!--Counter One Start--> */}
            <section className="counter-one">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5">
                            <div className="counter-one__left wow slideInLeft" data-wow-delay="100ms"
                                data-wow-duration="2500ms">
                                <div className="counter-one__img">
                                    <img src="images/resources/counter-img-1.png" alt=""></img>
                                    <div className="counter-one__video-link">
                                        <a href="https://www.youtube.com/watch?v=gcg1tUmTnGE" autoplay className="video-popup">
                                            <div className="counter-one__video-icon">
                                                <span className="icon-play"></span>
                                                <i className="ripple"></i>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-7">
                            <div className="counter-one__right wow slideInRight" data-wow-delay="100ms"
                                data-wow-duration="2500ms">
                                <div className="section-title text-left">
                                    <div className="section-title__tagline-box">
                                        <span className="section-title__tagline">Our Features</span>
                                    </div>
                                    <h2 className="section-title__title">Simplifying your Connection of <br></br>the challenges</h2>
                                </div>
                                <p className="counter-one__right-text">Network service involves the planning, implementation,
                                    and control of the efficient and effective movement and storage of goods and materials.
                                </p>
                                <ul className="counter-one__count-list list-unstyled">
                                    <li>
                                        <div className="counter-one__icon-and-count">
                                            <div className="counter-one__icon">
                                                <span className="icon-schedule"></span>
                                            </div>
                                            <div className="counter-one__count count-box">
                                                <h3 className="count-text" data-stop="100" data-speed="1500">00</h3>
                                                <span>+</span>
                                            </div>
                                        </div>
                                        <p className="counter-one__count-text">Happy Members</p>
                                    </li>
                                    <li>
                                        <div className="counter-one__icon-and-count">
                                            <div className="counter-one__icon">
                                                <span className="icon-schedule"></span>
                                            </div>
                                            <div className="counter-one__count count-box">
                                                <h3 className="count-text" data-stop="20" data-speed="1500">00</h3>
                                                <span className="counter-one__count-plus">+</span>
                                            </div>
                                        </div>
                                        <p className="counter-one__count-text">Key Features</p>
                                    </li>
                                    <li>
                                        <div className="counter-one__icon-and-count">
                                            <div className="counter-one__icon">
                                                <span className="icon-schedule"></span>
                                            </div>
                                            <div className="counter-one__count count-box">
                                                <h3 className="count-text" data-stop="3" data-speed="1500">00</h3>
                                                <span>k</span>
                                                <span className="counter-one__count-plus">+</span>
                                            </div>
                                        </div>
                                        <p className="counter-one__count-text">Clients Reviews</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--Counter One End--> */}

            {/* <!--Team One Start--> */}
            <section className="team-one">
                <div className="container">
                    <div className="team-one__top">
                        <div className="section-title text-left">
                            <div className="section-title__tagline-box">
                                <span className="section-title__tagline">// Our Founding Members </span>
                            </div>
                            <h2 className="section-title__title">Simplifying your Connection of <br></br>the challenges</h2>
                        </div>
                        {/* <div className="team-one__nav">
                            <div className="swiper-button-next1">
                                <i className="icon-arrow-left"></i>
                            </div>
                            <div className="swiper-button-prev1">
                                <i className="icon-arrow-right"></i>
                            </div>
                        </div> */}
                    </div>
                    {/* <div className="team-one__bottom"> */}

                    {/* <div className="swiper-wrapper"> */}
                    <Carousel arrows={true} responsive={ourTeamResponsive} showDots={false} autoPlay={true} infinite={true}>
                        {/* <!--Team One Single Start--> */}
                        <div className="swiper-slide">
                            <div className="team-one__single">
                                <div className="team-one__img">
                                    <img src="images/team/team-img-1.png" alt=""></img>
                                </div>
                                <div className="team-one__content">
                                    <h3 className="team-one__title"><a href="team-details.html">Brooklyn Simmons</a>
                                    </h3>
                                    <p className="team-one__sub-title">Quick Cargo</p>
                                    <div className="team-one__social">
                                        <a href="#"><span className="icon-instagram"></span></a>
                                        <a href="#"><span className="icon-linkedin-in"></span></a>
                                        <a href="#"><span className="icon-Vector"></span></a>
                                        <a href="#"><span className="icon-facebook-f"></span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--Team One Single End-->
                            <!--Team One Single Start--> */}
                        <div className="swiper-slide">
                            <div className="team-one__single">
                                <div className="team-one__img">
                                    <img src="images/team/team-img-2.png" alt=""></img>
                                </div>
                                <div className="team-one__content">
                                    <h3 className="team-one__title"><a href="team-details.html">Sakib Hasan</a>
                                    </h3>
                                    <p className="team-one__sub-title">Speedy Trans</p>
                                    <div className="team-one__social">
                                        <a href="#"><span className="icon-instagram"></span></a>
                                        <a href="#"><span className="icon-linkedin-in"></span></a>
                                        <a href="#"><span className="icon-Vector"></span></a>
                                        <a href="#"><span className="icon-facebook-f"></span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--Team One Single End-->
                            <!--Team One Single Start--> */}
                        <div className="swiper-slide">
                            <div className="team-one__single">
                                <div className="team-one__img">
                                    <img src="images/team/team-img-3.png" alt=""></img>
                                </div>
                                <div className="team-one__content">
                                    <h3 className="team-one__title"><a href="team-details.html">Fahda Hossain</a>
                                    </h3>
                                    <p className="team-one__sub-title">Efficient Transport</p>
                                    <div className="team-one__social">
                                        <a href="#"><span className="icon-instagram"></span></a>
                                        <a href="#"><span className="icon-linkedin-in"></span></a>
                                        <a href="#"><span className="icon-Vector"></span></a>
                                        <a href="#"><span className="icon-facebook-f"></span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--Team One Single End--> */}
                    </Carousel>
                    {/* </div> */}
                </div>
                {/* </div> */}
                {/* </div> */}
            </section>
            {/* <!--Team One End--> */}

            {/* <!--Start :: Why Are We Best --> */}
            <section className="why-are-we">
                <div className="container">
                    <div className='row'>
                        <div className='col-12'>
                            <div class="section-title text-center">
                                <div class="border-center section-title__tagline-box">
                                    <span class="section-title__tagline">Unique Membership Benefits</span>
                                </div>
                                <h2 class="section-title__title">Efficiency at its best with our<br></br> Networks services</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4">
                            <div className="why-are-we__left">
                                <div className="why-are-we__img">
                                    <img src="images/resources/why-we.png" alt=""></img>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8">
                            <div className="why-are-we__right">
                                {/* <div className="section-title text-left">
                                    <div className="section-title__tagline-box">
                                        <span className="section-title__tagline">// Unique Membership Benefits</span>
                                    </div>
                                    <h2 className="section-title__title">Efficiency at its best with our<br></br> Networks services
                                    </h2>
                                </div> */}
                                <ul className="why-are-we__list list-unstyled">
                                    <li>
                                        <div className="why-are-we__icon">
                                            <span className="icon-arrow-down-left"></span>
                                        </div>
                                        <div className="icon">
                                            <span className="icon-location why-are-we__location"></span>
                                        </div>
                                        <div className="content">
                                            <h3>Easy Quotation</h3>
                                            <p>Connection service involves the ntation and control </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="why-are-we__icon-2">
                                            <span className="icon-arrow-down-right"></span>
                                        </div>
                                        <div className="icon">
                                            <span className="icon-shopping-cart why-are-we__cart"></span>
                                        </div>
                                        <div className="content">
                                            <h3>Global Experts</h3>
                                            <p>Association service involves the ntation and control </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span className="icon-call why-are-we__call"></span>
                                        </div>
                                        <div className="content">
                                            <h3>Personalized Meeting</h3>
                                            <p>Logistic service involves the ntation and control </p>
                                        </div>
                                    </li>
                                </ul>
                                <div className="why-are-we__img-2">
                                    <img src="images/resources/why-we-img-2.png" alt=""></img>
                                    <div className="why-are-we__year wow fadeInLeft" data-wow-delay="300ms">
                                        <h3>Professional Business Support</h3>
                                        <p>Association service involves the planning, implementation, and control of the
                                            efficient and effective movement and storage</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--Why Are We End--> */}

            <section className="ft-contact-section position-relative contactUsImage" data-background="images/backgrounds/home-contact-img-2.png" >
                <div className="container">
                    <div className="ft-contact-content">
                        <div className="border-center section-title__tagline-box ms-5">
                            <span className="section-title__tagline">Contact us</span>
                        </div>
                        <div className="ft-section-title headline pera-content">
                            {/* <!-- <span className="sub-title">Contact us</span> --> */}
                            <h2>Need Help Regarding
                                One World One Network
                            </h2>
                            <p>We Are Excited to Work With You, Kindly Share Your Information Here For Getting In Touch With
                                You. </p>
                        </div>
                        <div className="ft-contact-form-wrapper">
                            <form action="#">
                                <div className="row">
                                    <div className="col-md-6">
                                        <input type="text" placeholder="Name" />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" placeholder="Email" />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" placeholder="Phone" />
                                    </div>
                                    <div className="col-md-6">
                                        {/* <label>Quantity of participants</label> */}
                                        <select class=" form-select-lg mb-3 form-select-home">
                                            <option selected>Category</option>
                                            <option value="1">Membership Related Queries</option>
                                            <option value="2">Network Related Queries</option>
                                            <option value="3">Payment Related Queries</option>
                                            <option value="3">Conference Related Queries</option>
                                            <option value="3">Other</option>
                                        </select>
                                    </div>
                                    <div className="col-md-12">
                                        <textarea name="#" placeholder="Your Massage"></textarea>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="faq-one__btn-box contact-btn">
                                            <button type="submit" className="thm-btn faq-one__btn">Submit
                                                <span></span></button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!--Testimonial One Start--> */}
            <section className="testimonial-one">
                <div className="container">
                    <div className="testimonial-one__inner">
                        <div className="testimonial-one__img-one">
                            <img src="images/testimonial/testimanoil-img-1.png" alt=""></img>
                        </div>
                        <div className="section-title text-center">
                            <div className="border-center section-title__tagline-box">
                                <span className="section-title__tagline">clients testimonial</span>
                            </div>
                            <h2 className="section-title__title">Member's success story<br></br> in their words</h2>
                        </div>
                        <div className="row">
                            <div className="col-xl-5"></div>
                            <div className="col-xl-7 col-lg-9">
                                <div className="testimonial-one__right">
                                    {/* <div className="thm-swiper__slider swiper-container" data-swiper-options='{
                                    "slidesPerView": 1, 
                                    "spaceBetween": 0,
                                    "speed": 2000,
                                    "loop": true,
                                    "pagination": {
                                        "el": ".swiper-dot-style1",
                                        "type": "bullets",
                                        "clickable": true
                                    },
                
                                    
                
                                    "navigation": {
                                        "nextEl": ".swiper-button-prev1",
                                        "prevEl": ".swiper-button-next1"
                                    },
                                    "autoplay": { "delay": 9000 },
                                    "breakpoints": {
                                            "0": {
                                                "spaceBetween": 0,
                                                "slidesPerView": 1
                                            },
                                            "375": {
                                                "spaceBetween": 0,
                                                "slidesPerView": 1
                                            },
                                            "575": {
                                                "spaceBetween": 0,
                                                "slidesPerView": 1
                                            },
                                            "768": {
                                                "spaceBetween": 30,
                                                "slidesPerView": 1
                                            },
                                            "992": {
                                                "spaceBetween": 30,
                                                "slidesPerView": 1
                                            },
                                            "1200": {
                                                "spaceBetween": 30,
                                                "slidesPerView":1
                                            },
                                            "1320": {
                                                "spaceBetween": 30,
                                                "slidesPerView":1
                                            }
                                        }
                                }'> */}
                                    <Carousel arrows={true} responsive={supplyChainResponsive} infinite={true} showDots={false} autoPlay={true}>
                                        <div className="swiper-wrapper">
                                            {/* <!--Testimonial One Single Start--> */}
                                            <div className="swiper-slide">
                                                <div className="testimonial-one__single">
                                                    <div className="testimonial-one__quote">
                                                        <span className="icon-quote"></span>
                                                    </div>
                                                    {/* <!-- <div className="testimonial-one__client-img">
                                                    <img src="images/testimonial/testimonial-1-1.jpg" alt=""></img>
                                                </div> --> */}
                                                    <div className="testimonial-one__ratting">
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star last-star"></span>
                                                    </div>
                                                    <h3 className="testimonial-one__client-name">Nafiz Bhuiyan</h3>
                                                    <p className="testimonial-one__client-sub-title">Manager</p>
                                                    <p className="testimonial-one__text">Logistic service involves the planning
                                                        implementation an and control of the efficient and effective
                                                        movement and storage of goods and materials Logistic service
                                                        involves the planning implementation and control</p>
                                                </div>
                                            </div>
                                            {/* <!--Testimonial One Single End-->
                                        <!--Testimonial One Single Start--> */}
                                            <div className="swiper-slide">
                                                <div className="testimonial-one__single">
                                                    <div className="testimonial-one__quote">
                                                        <span className="icon-quote"></span>
                                                    </div>
                                                    {/* <!-- <div className="testimonial-one__client-img">
                                                    <img src="images/testimonial/testimonial-1-2.jpg" alt=""></img>
                                                </div> --> */}
                                                    <div className="testimonial-one__ratting">
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star last-star"></span>
                                                    </div>
                                                    <h3 className="testimonial-one__client-name">Robert Son</h3>
                                                    <p className="testimonial-one__client-sub-title">Manager</p>
                                                    <p className="testimonial-one__text">Logistic service involves the planning
                                                        implementation an and control of the efficient and effective
                                                        movement and storage of goods and materials Logistic service
                                                        involves the planning implementation and control</p>
                                                </div>
                                            </div>
                                            {/* <!--Testimonial One Single End-->
                                        <!--Testimonial One Single Start--> */}
                                            <div className="swiper-slide">
                                                <div className="testimonial-one__single">
                                                    <div className="testimonial-one__quote">
                                                        <span className="icon-quote"></span>
                                                    </div>
                                                    {/* <!-- <div className="testimonial-one__client-img">
                                                    <img src="images/testimonial/testimonial-1-3.jpg" alt=""></img>
                                                </div> --> */}
                                                    <div className="testimonial-one__ratting">
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star last-star"></span>
                                                    </div>
                                                    <h3 className="testimonial-one__client-name">Harbert Spin</h3>
                                                    <p className="testimonial-one__client-sub-title">Manager</p>
                                                    <p className="testimonial-one__text">Logistic service involves the planning
                                                        implementation an and control of the efficient and effective
                                                        movement and storage of goods and materials Logistic service
                                                        involves the planning implementation and control</p>
                                                </div>
                                            </div>
                                            {/* <!--Testimonial One Single End-->
                                        <!--Testimonial One Single Start--> */}
                                            <div className="swiper-slide">
                                                <div className="testimonial-one__single">
                                                    <div className="testimonial-one__quote">
                                                        <span className="icon-quote"></span>
                                                    </div>
                                                    {/* <!-- <div className="testimonial-one__client-img">
                                                    <img src="images/testimonial/testimonial-1-4.jpg" alt=""></img>
                                                </div> --> */}
                                                    <div className="testimonial-one__ratting">
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star last-star"></span>
                                                    </div>
                                                    <h3 className="testimonial-one__client-name">Mainto Vula</h3>
                                                    <p className="testimonial-one__client-sub-title">Manager</p>
                                                    <p className="testimonial-one__text">Logistic service involves the planning
                                                        implementation an and control of the efficient and effective
                                                        movement and storage of goods and materials Logistic service
                                                        involves the planning implementation and control</p>
                                                </div>
                                            </div>
                                            {/* <!--Testimonial One Single End--> */}
                                        </div>
                                    </Carousel>
                                </div>
                            </div>

                            {/* </div> */}
                        </div>

                        <div className="testimonial-one__dot-style">
                            <div className="swiper-dot-style1"></div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--Testimonial One End--> */}

            {/* <!--Blog One Start--> */}
            <section className="blog-one">
                <div className="container">
                    <div className="section-title text-center">
                        <div className="border-center section-title__tagline-box">
                            <span className="section-title__tagline">Latests Blogs</span>
                        </div>
                        <h2 className="section-title__title">Catch our letest News<br></br>& Blogs</h2>
                    </div>
                    <div className="row">
                        {/* <!--Blog One Single Start--> */}
                        <div className="col-xl-4 col-lg-4 wow fadeInLeft" data-wow-delay="100ms">
                            <div className="blog-one__single">
                                <div className="blog-one__img-box">
                                    <a href="/blog-details">
                                        <div className="blog-one__img">
                                            <img src="images/blog/blog-img-1.png" alt=""></img>
                                        </div>
                                    </a>
                                    <div className="blog-one__date">
                                        <p>24 March</p>
                                    </div>
                                </div>
                                <div className="blog-one__content">
                                    <h3 className="blog-one__title"><a href="/blog-details">Your trusted network provider
                                        Express connection</a></h3>
                                    <p className="blog-one__text">It is a long established fact that a reader will williljl be
                                        distracted </p>
                                    <div className="blog-one__read-more">
                                        <a href="/blog-details">Read More<span className="icon-arrow-right"></span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--Blog One Single End-->
                    <!--Blog One Single Start--> */}
                        <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="200ms">
                            <div className="blog-one__single">
                                <div className="blog-one__img-box">
                                    <a href="/blog-details">
                                        <div className="blog-one__img">
                                            <img src="images/blog/blog-img-2.png" alt=""></img>
                                        </div>
                                    </a>
                                    <div className="blog-one__date">
                                        <p>24 March</p>
                                    </div>
                                </div>
                                <div className="blog-one__content">
                                    <h3 className="blog-one__title"><a href="/blog-details">network expertise for your the
                                        competitive advantage</a></h3>
                                    <p className="blog-one__text">It is a long established fact that a reader will williljl be
                                        distracted </p>
                                    <div className="blog-one__read-more">
                                        <a href="/blog-details">Read More<span className="icon-arrow-right"></span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--Blog One Single End-->
                    <!--Blog One Single Start--> */}
                        <div className="col-xl-4 col-lg-4 wow fadeInRight" data-wow-delay="300ms">
                            <div className="blog-one__single">
                                <div className="blog-one__img-box">
                                    <a href="/blog-details">
                                        <div className="blog-one__img">
                                            <img src="images/blog/blog-img-3.png" alt=""></img>
                                        </div>
                                    </a>
                                    <div className="blog-one__date">
                                        <p>24 March</p>
                                    </div>
                                </div>
                                <div className="blog-one__content">
                                    <h3 className="blog-one__title"><a href="/blog-details">Streamlining your supply chain
                                        processes Express </a></h3>
                                    <p className="blog-one__text">It is a long established fact that a reader will williljl be
                                        distracted </p>
                                    <div className="blog-one__read-more">
                                        <a href="/blog-details">Read More<span className="icon-arrow-right"></span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--Blog One Single End--> */}
                    </div>
                </div>
            </section>
            {/* <!--Blog One End--> */}
        </div>

    )
}

export default Home
